import React from 'react'
import { useAppSelector } from 'redux/store/store'

export const ChatwootWidget: React.FC = () => {
  const { userInfo } = useAppSelector(state => state.AuthReducer)

  React.useEffect(() => {
    const refWindow: any = window

    // Paste the script from inbox settings except the <script> tag
    

    // refWindow.addEventListener('chatwoot:ready', function () {
    //   // docs: https://www.chatwoot.com/docs/product/channels/live-chat/sdk/setup
    //   const key = 'AC49FmGgduDGUZhpUWTCQW2V'
    //   const message = userInfo?._id

    //   const hash = Hmac256(key, message)

    //   refWindow.$chatwoot.setUser(userInfo?._id, {
    //     email: `${userInfo?.phone}@gmail.com`,
    //     name: userInfo?.name,
    //     avatar_url: userInfo.avatar,
    //     phone_number: userInfo?.phone,
    //     identifier_hash: hash,
    //   })
    // })
  }, [userInfo])

  return null
}
