export const ADMIN_ROUTER_PATH = {
  HOME: '/',
  BANK_CARD: '/bank-card',
  DELIVERY_ADDRESS: '/delivery-address',
  HISTORY: '/history',
  HISTORY_TRANSACTION: '/history-transaction',
  MEMBER_RANK: '/member-rank',
  MY_GROUP: '/my-group',
  ORDER: '/order',
  DETAIL_ORDER: '/detail-order',
  BILL: '/bill',
  PROFILE: '/profile',
  SUPPORT: '/support',
  WITHDRAW_MONEY: '/withdraw-money',
  RECHARGE_MONEY: '/recharge-money',
  PAYMENT: '/payment',
  DEPOSIT_PASS: '/deposit-pass',
  CHANGE_PASS: '/change-pass',
}
