import i18n from './translation'
function strings() {
  return {
    title_shop: i18n.t('title.shop'),
    title_product: i18n.t('title.product'),
    title_account: i18n.t('title.account'),
    title_customer: i18n.t('title.customer'),
    title_dashboard: i18n.t('title.dashboard'),
    title_order: i18n.t('title.order'),
    title_news: i18n.t('title.news'),
    title_config: i18n.t('title.config'),
    title_report: i18n.t('title.report'),
    title_category: i18n.t('title.category'),
    placeholder_login_name: i18n.t('placeholder.login_name'),
    placeholder_password: i18n.t('placeholder.password'),
    request_fail: i18n.t('message.request_fail'),
    connect_server_error: i18n.t('message.connect_server_error'),
  }
}
export default strings
